import './CustomEditor.scss';
import React from 'react';
import { 
    BtnBold,
    BtnBulletList,
    BtnClearFormatting,
    BtnItalic,
    BtnLink,
    BtnNumberedList,
    BtnRedo,
    BtnStrikeThrough,
    BtnUnderline,
    BtnUndo,
    Editor,
    EditorProvider,
    Separator,
    Toolbar
} from 'react-simple-wysiwyg';

interface CustomEditorProps {
  value: string;
  onChange: (e: { target: { value: string } }) => void;
  placeholder?: string;
}

const CustomEditor: React.FC<CustomEditorProps> = ({ value, onChange, placeholder }) => {
  const addTargetBlankToLinks = (html: string) => {
    return html.replace(/<a\s+([^>]*)>/g, '<a $1 target="_blank" rel="noopener noreferrer">');
  };

  const handleChange = (e: { target: { value: string } }) => {
    const updatedValue = addTargetBlankToLinks(e.target.value);
    onChange({ target: { value: updatedValue } });
  };

  return (
    <EditorProvider>
      <div className="custom-editor">
        <Editor
          value={value}
          onChange={handleChange}
        >
          <Toolbar>
            {/* <BtnUndo />
            <BtnRedo /> */}
            <BtnBold />
            <BtnItalic />
            <BtnUnderline />
            <BtnStrikeThrough />
            <Separator />
            <BtnNumberedList />
            <BtnBulletList />
            <Separator />
            <BtnLink />
            <BtnClearFormatting />
          </Toolbar>
        </Editor>
      </div>
    </EditorProvider>
  );
};

export default CustomEditor;
