import React from 'react';
import './JusticeBalance.scss';
import balancePole from '../../images/justice-balance/balance-pole.png';
import balanceArms from '../../images/justice-balance/balance-arms.png';
import balanceScale from '../../images/justice-balance/balance-scale.png';
import balanceHandshake from '../../images/justice-balance/balance-handshake.png';
import balanceBible from '../../images/justice-balance/balance-bible.png';

interface JusticeBalanceProps {
  left_text: string;
  left_weight: number;
  right_text: string;
  right_weight: number;
}

// Hardcoded values for each weight combination
const balanceSettings: Record<string, { rotation: number; leftTranslateY: number; rightTranslateY: number; leftTranslateX: number; rightTranslateX: number }> = {
  '0,10': { rotation: 25, leftTranslateY: -140, rightTranslateY: 100, leftTranslateX: -170, rightTranslateX: 140 },
  '1,9': { rotation: 20, leftTranslateY: -110, rightTranslateY: 80, leftTranslateX: -180, rightTranslateX: 155 },
  '2,8': { rotation: 15, leftTranslateY: -90, rightTranslateY: 60, leftTranslateX: -185, rightTranslateX: 165 },
  '3,7': { rotation: 10, leftTranslateY: -60, rightTranslateY: 40, leftTranslateX: -190, rightTranslateX: 175 },
  '4,6': { rotation: 5, leftTranslateY: -35, rightTranslateY: 10, leftTranslateX: -195, rightTranslateX: 185 },
  '5,5': { rotation: 0, leftTranslateY: -10, rightTranslateY: -10, leftTranslateX: -190, rightTranslateX: 190 },
  '6,4': { rotation: -5, leftTranslateY: 10, rightTranslateY: -40, leftTranslateX: -180, rightTranslateX: 185 },
  '7,3': { rotation: -10, leftTranslateY: 35, rightTranslateY: -60, leftTranslateX: -175, rightTranslateX: 190 },
  '8,2': { rotation: -15, leftTranslateY: 60, rightTranslateY: -85, leftTranslateX: -165, rightTranslateX: 190 },
  '9,1': { rotation: -20, leftTranslateY: 80, rightTranslateY: -110, leftTranslateX: -155, rightTranslateX: 185 },
  '10,0': { rotation: -25, leftTranslateY: 105, rightTranslateY: -140, leftTranslateX: -145, rightTranslateX: 175 },
};

const JusticeBalance: React.FC<JusticeBalanceProps> = ({ left_text, left_weight, right_text, right_weight }) => {
  const key = `${left_weight},${right_weight}`;
  const { rotation, leftTranslateY, rightTranslateY, leftTranslateX, rightTranslateX } = balanceSettings[key] || { rotation: 0, leftTranslateY: 0, rightTranslateY: 0, leftTranslateX: 0, rightTranslateX: 0 };

  const scaleWidth = 402;

  // Convert newlines to <br /> for HTML rendering
  const formatText = (text: string) => {
    return text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <div className="main-container">
      <div className="balance-container">
        <div className="balance">
          <img src={balancePole} alt="Pole" className="balance-pole" />
          <div className="arms" style={{ transform: `rotate(${rotation}deg)` }}>
            <img src={balanceArms} alt="Arms" className="balance-arms" />
          </div>
          <div className="left-platform" style={{ 
              transform: `translateY(${leftTranslateY}px) translateX(${leftTranslateX}px)`,
              position: 'absolute',
              left: `calc(50% - ${scaleWidth / 2}px)`
            }}>
            <img src={balanceScale} alt="Left Scale" className="balance-scale" />
            <div className="left-text">{formatText(left_text)}</div>
          </div>
          <div className="right-platform" style={{ 
              transform: `translateY(${rightTranslateY}px) translateX(${rightTranslateX}px)`,
              position: 'absolute',
              right: `calc(50% - ${scaleWidth / 2}px)`
            }}>
            <img src={balanceScale} alt="Right Scale" className="balance-scale" />
            <div className="right-text">{formatText(right_text)}</div>
          </div>
        </div>
      </div>
      <div className="images-container">
        <img src={balanceHandshake} alt="Handshake" className="balance-handshake" />
        <img src={balanceBible} alt="Bible" className="balance-bible" />
      </div>
    </div>
  );
};

export default JusticeBalance;
