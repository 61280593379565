export const fetchWithAuth = async (url: string, options: RequestInit = {}) => {
    const token = localStorage.getItem('token');
  
    if (token) {
      options.headers = {
        ...options.headers,
        'Authorization': `Bearer ${token}`,
      };
    }
  
    try {
      const response = await fetch(url, options);
  
      if (response.status === 403 || response.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/login';
        return response;
      }
  
      return response;
    } catch (error) {
      console.error('Fetch error:', error);
      throw error;
    }
  };
  