import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import './Admin.scss';
import { fetchWithAuth } from './fetchUtils';
import JusticeBalance from '../../components/justice-balance/JusticeBalance';
import CustomEditor from '../../components/custom-editor/CustomEditor';

const Admin: React.FC = () => {
  const [welcomeText, setWelcomeText] = useState('');
  const [inputWelcomeText, setInputWelcomeText] = useState('');
  const [newsList, setNewsList] = useState<{ id: number; text: string }[]>([]);
  const [newNewsText, setNewNewsText] = useState('');
  const [fetchedBalanceItem, setFetchedBalanceItem] = useState<{ left_text: string; left_weight: number; right_text: string; right_weight: number } | null>(null);
  const [leftText, setLeftText] = useState('');
  const [leftWeight, setLeftWeight] = useState<number>(0);
  const [rightText, setRightText] = useState('');
  const [rightWeight, setRightWeight] = useState<number>(0);
  const [shouldRenderBalance, setShouldRenderBalance] = useState(false);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const leftTextRef = useRef<HTMLTextAreaElement>(null);
  const rightTextRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }

    // Fetch admin rights
    fetchWithAuth(`${backendUrl}/users/me`)
      .then(response => response.json())
      .catch(() => enqueueSnackbar('Failed to fetch admin rights', { variant: 'error' }));

    // Fetch welcome text
    fetchWithAuth(`${backendUrl}/welcome-text/`)
      .then(response => response.json())
      .then(data => {
        setWelcomeText(data.text);
        setInputWelcomeText(data.text);
      })
      .catch(() => enqueueSnackbar('Failed to fetch welcome text', { variant: 'error' }));

    // Fetch news list
    fetchWithAuth(`${backendUrl}/news/`)
      .then(response => response.json())
      .then(data => setNewsList(data))
      .catch(() => enqueueSnackbar('Failed to fetch news list', { variant: 'error' }));

    // Fetch balance items
    fetchWithAuth(`${backendUrl}/balance-items/`)
      .then(response => response.json())
      .then(data => {
        if (data.length > 0) {
          const item = data[0]; // Using only one item
          setFetchedBalanceItem(item);
          setLeftText(item.left_text);
          setLeftWeight(item.left_weight);
          setRightText(item.right_text);
          setRightWeight(item.right_weight);
          setShouldRenderBalance(true)
        }
        if (leftTextRef.current) {
          adjustTextareaHeight(leftTextRef.current);
        }
        if (rightTextRef.current) {
          adjustTextareaHeight(rightTextRef.current);
        }
      })
      .catch(() => enqueueSnackbar('Failed to fetch scale items', { variant: 'error' }));
  }, [navigate, enqueueSnackbar, backendUrl]);


  const handleUpdateWelcomeText = () => {
    fetchWithAuth(`${backendUrl}/welcome-text/`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ text: inputWelcomeText }), // Send inputWelcomeText to the server
    })
      .then(() => {
        setWelcomeText(inputWelcomeText); // Update the displayed welcomeText
        enqueueSnackbar('Welcome text updated successfully', { variant: 'success' });
      })
      .catch(() => enqueueSnackbar('Failed to update welcome text', { variant: 'error' }));
  };

  const handleAddNews = () => {
    fetchWithAuth(`${backendUrl}/news/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ text: newNewsText }),
    })
      .then(response => response.json())
      .then(data => {
        setNewsList([...newsList, data]);
        setNewNewsText('');
        enqueueSnackbar('News added successfully', { variant: 'success' });
      })
      .catch(() => enqueueSnackbar('Failed to add news', { variant: 'error' }));
  };

  const handleDeleteNews = (id: number) => {
    fetchWithAuth(`${backendUrl}/news/`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id }),
    })
      .then(() => {
        setNewsList(newsList.filter(news => news.id !== id));
        enqueueSnackbar('News deleted successfully', { variant: 'success' });
      })
      .catch(() => enqueueSnackbar('Failed to delete news', { variant: 'error' }));
  };

  const handleUpdateTexts = () => {
    fetchWithAuth(`${backendUrl}/balance-items/`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        left_text: leftText,
        left_weight: leftWeight,
        right_text: rightText,
        right_weight: rightWeight,
      }),
    })
      .then(async (response) => {
        if (response.ok) {
          setFetchedBalanceItem({ left_text: leftText, left_weight: leftWeight, right_text: rightText, right_weight: rightWeight });
          setShouldRenderBalance(true);
          enqueueSnackbar('Scale items updated successfully', { variant: 'success' });
        } else {
          const errorData = await response.json();
          const errorMessage = errorData.detail || 'Failed to update scale items';
          enqueueSnackbar(errorMessage, { variant: 'error' });
        }
      })
      .catch((error) => {
        console.error('Error during update:', error);
        enqueueSnackbar('Failed to update scale items', { variant: 'error' });
      });
  };

  const adjustTextareaHeight = (textarea: HTMLTextAreaElement) => {
    textarea.style.height = 'auto';
    // For non-capped textareas
    if (textarea.dataset.maxHeight === 'false') {
      textarea.style.height = `${Math.min(textarea.scrollHeight, 200)}px`;
    } else {
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>, setter: React.Dispatch<React.SetStateAction<string>>) => {
    const value = e.target.value;
    // Limit to 5 lines
    const lines = value.split('\n');
    if (lines.length > 5 && e.target.dataset.maxHeight === 'true') {
      return;
    }
    setter(value);
    adjustTextareaHeight(e.target);
  };

  return (
    <div className="admin-container">
      <h2>Admin Dashboard</h2>
      <div className="section">
        <h2>Welcome Text</h2>
        <CustomEditor
          value={inputWelcomeText}
          onChange={(e) => setInputWelcomeText(e.target.value)}
        />
        <button onClick={handleUpdateWelcomeText}>Update Welcome Text</button>
      </div>

      <div className="section">
        <h2>News Area</h2>
        <div className="news-list">
          {newsList.map(news => (
            <div key={news.id} className="news-item">
              <span dangerouslySetInnerHTML={{ __html: news.text }} />
              <button onClick={() => handleDeleteNews(news.id)}>Delete</button>
            </div>
          ))}
        </div>
        <CustomEditor
          value={newNewsText}
          onChange={(e) => setNewNewsText(e.target.value)}
        />
        <button onClick={handleAddNews}>Add News</button>
      </div>

      <div className="section">
        <h2>Justice Scale</h2>
        <div className="text-weights">
          <div>
            <label>
              Left Text
              <textarea
                ref={leftTextRef}
                value={leftText}
                onChange={(e) => handleTextareaChange(e, setLeftText)}
                placeholder="Left text"
                data-max-height="true"
                rows={1}
              />
            </label>
            <label>
              Left Weight
              <input
                type="number"
                min="0"
                max="10"
                value={leftWeight}
                onChange={(e) => setLeftWeight(Number(e.target.value))}
                placeholder="Left weight"
              />
            </label>
          </div>
          <div>
            <label>
              Right Text
              <textarea
                ref={rightTextRef}
                value={rightText}
                onChange={(e) => handleTextareaChange(e, setRightText)}
                placeholder="Right text"
                data-max-height="true"
                rows={1}
              />
            </label>
            <label>
              Right Weight
              <input
                type="number"
                min="0"
                max="10"
                value={rightWeight}
                onChange={(e) => setRightWeight(Number(e.target.value))}
                placeholder="Right weight"
              />
            </label>
          </div>
        </div>
        <button onClick={handleUpdateTexts}>Update Scale</button>
      </div>
      <div>
        {shouldRenderBalance && fetchedBalanceItem ? (
          <JusticeBalance 
            left_text={fetchedBalanceItem.left_text} 
            left_weight={fetchedBalanceItem.left_weight} 
            right_text={fetchedBalanceItem.right_text} 
            right_weight={fetchedBalanceItem.right_weight} 
          />
        ) : null}
      </div>
    </div>
  );
};

export default Admin;
