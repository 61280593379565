import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Homepage from "./pages/homepage/Homepage";
import ComingSoon from "./pages/homepage/ComingSoon";
import Login from "./pages/login/Login";
import Admin from "./pages/admin/Admin";
import "./App.scss";

import ReactGA from 'react-ga4';

const trackingId = process.env.REACT_APP_GA_TRACKING_ID;
if (trackingId) {
  ReactGA.initialize(trackingId);
} else {
  console.error('Google Analytics tracking ID is not set');
}

const App: React.FC = () => {
  useEffect(() => {
    ReactGA.send("pageview");
  }, []);

  const PrivateRoute: React.FC<{ element: JSX.Element }> = ({ element }) => {
    const token = localStorage.getItem('token');
    return token ? element : <Navigate to="/login" />;
  };

  return (
    <BrowserRouter>
      <div id="app">
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/coming-soon" element={<ComingSoon />} />
          <Route path="/login" element={<Login />} />
          <Route path="/admin" element={<PrivateRoute element={<Admin />} />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
