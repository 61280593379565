import React from 'react';
import './Homepage.scss';
import './css/animate.css';

const ComingSoon: React.FC = () => {
    
  return (
    <>
    <div>
        <header>
            <div className="topbar">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-6">
                            <div className="row">
                            <div className="col-lg-3 offset-1">
                                <p><i className="fas fa-phone"></i> <a href="tel:800-317-0625" style={{ textDecoration: 'none' }}>800-317-0625</a></p>
                            </div>
                            <div className="col-lg-4">
                                <p><i className="fas fa-envelope"></i> <a href="mailto:info@tomflash.com" style={{ textDecoration: 'none' }}>info@tomflash.com </a></p>
                            </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                        <ul>
                            <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                            <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                            <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                            <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
                        </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="lgo-bar">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="logo-box">
                                <a href="/"><img src={require("./images/fotter-logo.png")}/></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button id="nav-toggle"></button>
        </header>

        <section className="coming-soon">
            <div className="container">
                <div className="row">
                    <a href="/"  style={{ textDecoration: 'none' }} className="bkhome">Back to Home</a>
                    <h3>UNDER CONTRUCTION
                    </h3>
                    <p>FOLLOW <a href="https://newsflashtom.club" target="_blank" style={{ textDecoration: 'none' }}>NEWSFLASHTOM</a> FOR NEW ARTICLES </p>
                    {/* <div className="cmn-form">
                        <form>
                            <input type="text" placeholder="Email Address"/>
                            <button type="submit">Submit</button>
                        </form>
                    </div> */}
                    <div className="footersec cmng">
                        <ul>
                            <li><a href="#"><i className="fab fa-facebook-f" aria-hidden="true"></i></a></li>
                            <li><a href="#"><i className="fab fa-instagram" aria-hidden="true"></i></a></li>
                            <li><a href="#"><i className="fab fa-twitter" aria-hidden="true"></i></a></li>
                            <li><a href="#"><i className="fab fa-linkedin-in" aria-hidden="true"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>

        <section className="aboutsec">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-sm-12">
                        <div className="logo-box">
                                <a href="/"><img src={require("./images/fotter-logo.png")}/></a>
                        </div>
                        <p>Tom Flash estate sales is a dedicated platform for manufacturers, producers of goods and services, retailers, and wholesalers. Many of our items for sale in our estate sales section are from estate liquidations and when the item is sold out, we may never have that item for sale again.</p>
                        <ul className="buttonsec">
                            <li><a href="https://estatesal.com" style={{ textDecoration: 'none' }}>TOM FLASH ESTATE SALES</a></li>
                            <li><a href="coming-soon" style={{ textDecoration: 'none' }}>SPORTS HANDICAPPING</a></li>
                            <li><a href="coming-soon" style={{ textDecoration: 'none' }}>ART AND PHOTOGRAPHY </a></li>
                            <li><a href="coming-soon" style={{ textDecoration: 'none' }}>INSURANCE PRODUCTS</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>

        <section className="footersec">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-sm-12">
                        <ul>
                            <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                            <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                            <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                            <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>

        <div className="copyright">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-sm-12">
                        <p>Copyright © 2024 Tom Flash - All Rights Reserved.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  );
}

export default ComingSoon;
