import React, { useEffect, useState } from 'react';
import './Homepage.scss';
import './css/animate.css';
import { fetchWithAuth } from '../admin/fetchUtils';
import JusticeBalance from '../../components/justice-balance/JusticeBalance';
import NewsArea from '../../components/news-area/NewsArea';

const Homepage: React.FC = () => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [welcomeText, setWelcomeText] = useState('');
  const [newsText, setNewsText] = useState('');
  const [showNews, setShowNews] = useState(false);
  const [fetchedBalanceItem, setFetchedBalanceItem] = useState<{ left_text: string; left_weight: number; right_text: string; right_weight: number } | null>(null); // Fetched data
  const [showBalance, setShowBalance] = useState(false);

  useEffect(() => {
    // Fetch welcome text
    fetchWithAuth(`${backendUrl}/welcome-text/`)
      .then(response => response.json())
      .then(data => {
        setWelcomeText(data.text);
      })
      .catch(() => setWelcomeText('Tom Flash estate sales is a dedicated platform for manufacturers, producers of goods and services, retailers, and wholesalers. Many of our items for sale in our estate sales section are from estate liquidations and when the item is sold out, we may never have that item for sale again.'));
    
// Fetch news text
fetchWithAuth(`${backendUrl}/news/`)
  .then(response => response.json())
  .then(data => {
    if (data.length === 0) {
      setNewsText('No news available.');
      setShowNews(false);
    } else {
      const formattedNews = data
        .map((item: { text: string }) => item.text)
        .join('\n\n');
      setNewsText(formattedNews);
      setShowNews(true);
    }
  })
  .catch(() => {
    setNewsText('Failed to load news.');
    setShowNews(false);
  });

    // Fetch balance items
    fetchWithAuth(`${backendUrl}/balance-items/`)
      .then(response => response.json())
      .then(data => {
        if (data.length > 0) {
          const item = data[0];
          setFetchedBalanceItem(item);
          setShowBalance(true);
        }
      })
      .catch(() => setShowBalance(false));
  },[backendUrl]);

  return (
    <>
      <header>
        <div className="topbar">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-6">
                <div className="row">
                  <div className="col-lg-3 offset-1">
                    <p>
                      <i className="fas fa-phone"></i>{' '}
                      <a href="tel:800-317-0625" style={{ textDecoration: 'none' }}>800-317-0625</a>
                    </p>
                  </div>
                  <div className="col-lg-6">
                    <p>
                      <i className="fas fa-envelope"></i>{' '}
                      <a href="mailto:tomflash1@protonmail.com" style={{ textDecoration: 'none' }}>Contact us</a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-6">
                <ul>
                  <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                  <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                  <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                  <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="lgo-bar">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="logo-box">
                  <a href="/">
                    <img src={require("./images/fotter-logo.png")} alt="Tom Flash Logo" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button id="nav-toggle"></button>
      </header>

      {/* banner start */}
      <section className="banner-silde">
        <video autoPlay loop muted width="100%" height="450" id='video_preview'>
          <source src={require("./videos/main-bg.mp4")} type="video/mp4" />
          <source src={require("./videos/main-bg.mp4")} type="video/ogg" />
          Your browser does not support the video tag.
        </video>
        <span className="dots"></span>
        <span className="dots1"></span>
        <section className="tom-flast for-onbanner">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-4">
                <div className="side-bar">
                  <h2>
                    <img src={require("./images/menu-icon.png")} alt="Menu Icon" /> MENU
                  </h2>
                  <div className="sidenav">
                    <a href="http://estatesal.com" target="_blank" rel="noopener noreferrer">Tom Flash Estate Sales</a>
                    <a href="coming-soon" target="_blank" rel="noopener noreferrer">Art and Photography</a>
                    <a href="coming-soon" target="_blank" rel="noopener noreferrer">Sports Handicapping</a>
                    <a href="https://binsuredhere.store" target="_blank" rel="noopener noreferrer">Insurance Products</a>
                    <a href="coming-soon" target="_blank" rel="noopener noreferrer">Real Commercials</a>
                    <a href="http://newsflashtom.club" target="_blank" rel="noopener noreferrer">News Flash Tom Info</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>

      <section className="afterban-img">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="int-img">
                <div className="right-side-bar">
                  <h3>Welcome to Tom Flash</h3>
                  <p>
                    <span dangerouslySetInnerHTML={{ __html: welcomeText }} />
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            {showNews && newsText ? (
            <NewsArea newsText={newsText}></NewsArea>
            ) : null}
          </div>
          <div>
            {showBalance && fetchedBalanceItem ? (
            <JusticeBalance 
              left_text={fetchedBalanceItem.left_text} 
              left_weight={fetchedBalanceItem.left_weight} 
              right_text={fetchedBalanceItem.right_text} 
              right_weight={fetchedBalanceItem.right_weight} 
            />
          ) : null}
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="img-banner">
                <a target="_blank" href="http://binsuredhere.store">
                  <img src={require("./images/4_term_life_insurance.png")} alt="Binsuredhere Advertisement" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* About Sec */}
      <section className="aboutsec">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="logo-box">
                <a href="/">
                  <img src={require("./images/fotter-logo.png")} alt="Tom Flash Logo" />
                </a>
              </div>
              <p>
                Tom Flash estate sales is a dedicated platform for manufacturers, producers of goods and services,
                retailers, and wholesalers. Many of our items for sale in our estate sales section are from estate
                liquidations and when the item is sold out, we may never have that item for sale again.
              </p>
              <ul className="buttonsec">
                <li><a href="http://estatesal.com/" style={{ textDecoration: 'none' }}>TOM FLASH ESTATE SALES</a></li>
                <li><a href="coming-soon" style={{ textDecoration: 'none' }}>SPORTS HANDICAPPING</a></li>
                <li><a href="coming-soon" style={{ textDecoration: 'none' }}>ART AND PHOTOGRAPHY</a></li>
                <li><a href="https://binsuredhere.store" style={{ textDecoration: 'none' }}>INSURANCE PRODUCTS</a></li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Footer Sec */}
      <section className="footersec">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <ul>
                <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <div className="copyright">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <p>Copyright © 2024 Tom Flash - All Rights Reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Homepage;
