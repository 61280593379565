import React from 'react';
import './NewsArea.scss';

interface NewsAreaProps {
  newsText: string;
  minHeight?: number;
  maxHeight?: number;
}

const NewsArea: React.FC<NewsAreaProps> = ({
  newsText,
  minHeight = 100,
  maxHeight = 300,
}) => {
  return (
    <div
      className="scrollable-rectangle"
      style={{ minHeight: `${minHeight}px`, maxHeight: `${maxHeight}px` }}
    >
      <div className="content">
        <span dangerouslySetInnerHTML={{ __html: newsText }} />
      </div>
    </div>
  );
};

export default NewsArea;
